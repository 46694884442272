/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

@import '../theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .hero--variant3 &,
    .hero--variant4 &  {
      flex-direction: unset;
    }
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
    }
    &--positioned-stretched {
      position: absolute;
      z-index: auto;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
  }
  &__line {
    // make sure we do not pick up flex alignment rules
    display: block;
    margin-bottom: 14px;
    // compensate for the WYSIWYG adding extra paragraphs
    .hero--variant4 & {
      margin-bottom: 25px;
    }
    .hero--variant3 &,
    .hero--variant5 & {
      margin-bottom: 25px;
    }
    .hero--variant2 & {
      margin-bottom: 30px;
    }
    .sd-grid-formatter & {
      margin-bottom: 40px;
      .elc-basic-tout__header {
        margin-bottom: 30px;
      }
      a.elc-media-asset {
        line-height: 3.5;
      }
    }
    p {
      margin: 0;
      padding: 0;
    }
    &--link {
      padding: 0 12px 12px;
      span {
        padding: 0 20px;
      }
    }
    &--content {
      margin-bottom: 25px;
      .hero--variant2 & {
        margin-bottom: 30px;
      }
      .hero--variant1 & {
        margin-bottom: 23px;
      }
      .hero--variant3 &,
      .hero--variant5 & {
        margin-bottom: 30px;
      }
    }
  }
  &__link {
    padding-bottom: 1em;
    .hero--variant5 & {
      padding-bottom: 30px;
    }
  }
  &__links {
    display: block;
    min-height: 1.5em;
    padding-bottom: 12px;
    &--2up {
      > div:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  &.basic-tout,
  &.video-tout {
    &__content {
      display: block;
    }
    .content-block__line--link {
      display: inline-block;
    }
  }
  .elc-video-tout-content-below {
    // match small vertical padding rules for top margin
    margin-top: 20px;
    @include breakpoint($medium-up) {
      margin-top: 25px;
    }
    @include breakpoint($landscape-up) {
      margin-top: 35px;
    }
    @include breakpoint($xlarge-up) {
      margin-top: 40px;
    }
  }
  // Text Alignment
  &.text-align--#{$ldirection} {
    text-align: $ldirection;
  }
  &.text-align--center {
    text-align: center;
  }
  &.text-align--#{$rdirection} {
    text-align: $rdirection;
  }
}
